#header {
  padding: 20px 0;
  width: 100vw;

  #header-title {
    font-family: Roboto;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
  }
}

#main {
  background-color: #efefef;
  font-family: Helvetica;

  #masthead {
    background-image: url("../public/cover.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    height: 700px;
    justify-content: space-around;

    #info-header {
      color: white;
      font-family: Roboto;
      font-size: 40px;
      font-weight: 500;
      margin-bottom: 30px;
      padding: 0 10%;
    }
  }

  #prevgen {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 5% 0;
    width: 100vw;

    #prevgen-header {
      display: flex;
      flex-direction: column;
      font-size: 30px;
      justify-content: space-around;
      padding: 0 5%;
      text-align: center;
      width: 40%;
    }

    #prevgen-image {
      border-radius: 100px;
      margin: 0 5%;
      overflow: hidden;
      width: 40%;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}

#footer {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  padding: 20px 0;
  text-align: center;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
